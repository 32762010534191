.cd-pricing-switcher {
	text-align: center;
}
.cd-pricing-switcher .fieldset {
	display: inline-block;
	position: relative;
	padding: 0%;
	border-radius: 50em;
	/* border: 1px solid #466fa5; */
	background-color: #e9edfc;
	width: 340px;
}
.cd-pricing-switcher input[type='radio'] {
	position: absolute;
	opacity: 0;
}
.cd-pricing-switcher label {
	position: relative;
	z-index: 1;
	display: inline-block;
	float: left;
	width: 50%;
	height: 56px;
	line-height: 55px;
	cursor: pointer;
	font-size: 18px;
	font-weight: 500;
	color: #222222;
}
.cd-pricing-switcher .cd-switch {
	position: absolute;
	left: 0px;
	height: 56px;
	width: 51%;
	background-color: #1b5998;
	border-radius: 50em;
	-webkit-transition: -webkit-transform 0.2s;
	-moz-transition: -moz-transform 0.2s;
	transition: transform 0.2s;
}
.cd-pricing-switcher input[type='radio']:checked + label + .cd-switch,
.cd-pricing-switcher
	input[type='radio']:checked
	+ label:nth-of-type(n)
	+ .cd-switch {
	-webkit-transform: translateX(97%);
	-moz-transform: translateX(97%);
	-ms-transform: translateX(97%);
	-o-transform: translateX(97%);
	transform: translateX(97%);
}

.cd-pricing-switcher input[type='radio']:checked + label {
	color: #ffffff;
}

.no-js .cd-pricing-switcher {
	display: none;
}

.divider {
	align-self: center;
	border-top: 1px solid #1b5998;
	width: 100%;
	margin-bottom: 1.5rem;
}
