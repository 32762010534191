@media all and (min-width: 100vw) {
	.Login {
		padding: 60px 0;
	}

	.Login form {
		margin: 0 auto;
		max-width: 320px;
	}
	.Login form .Register {
		padding: 30px;
	}

	.login-divider {
		margin: 40px 0px;
		position: relative;
		text-align: center;
	}

	.login-divider::before {
		display: inline-block;
		border-top: 1px solid rgb(184, 196, 194);
		content: '';
		margin: 0px auto;
		position: absolute;
		height: 0px;
		inset: 50% 0px 0px;
		z-index: -1;
		transform: translateY(-50%);
	}

	.dividerText {
		background: #ffffff;
		padding: 0px 10px;
		font-size: 14px;
		font-weight: 400;
		line-height: 16px;
		color: #718096;
		text-align: center;
	}
}
