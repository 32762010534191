body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

select.form-control,
textarea.form-control,
input.form-control {
	font-size: 16px;
}
input[type='file'] {
	width: 100%;
}

.brandContainer {
	width: 110px;
	margin-left: 0px;
	margin-right: 56px;
	flex-grow: 1;
}

.ul {
	width: 110px;
	margin-left: 0px;
}

.brandContainer .brand-logo {
	/* padding-top: -15px; */
	width: 160px;
}

.actionBar {
	margin-top: 16px;
}

.tableBar {
	margin-top: 16px;
}

.tableContent {
	/* margin-top: 20px; */
	margin: 0;
	width: 100%;
	border-spacing: 0; /* Removes the cell spacing via CSS */
	border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
}

.tableContent .checkBox {
	width: 45px !important;
	padding-left: 7px !important;
}

.tableContent th {
	/* border: 1px solid #edf2f7; */
	width: 300px;
	text-align: left;
	padding-left: 8px;
	padding-right: 8px;
	text-transform: uppercase;
	font-size: 1rem;
	font-weight: 600;
}

.tableContent td {
	border-top: 1px solid #edf2f7;
	border-bottom: 1px solid #edf2f7;
	width: 300px;
	text-align: left;
	padding-left: 10px;
}

.tableContainer {
	width: 100%;
	overflow-x: auto;
}

.tableContent th .aligner {
	display: flex;
	align-items: center;
}

.tableContent th .aligner .icon {
	margin-left: 8px;
	height: 24px;
}

.Toastify__toast {
	font-family: unset !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
	background: #4a93dc !important;
}
.Toastify__toast-theme--colored.Toastify__toast--success {
	background: #66cb94 !important;
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
	background: #f7c16f !important;
}
.Toastify__toast-theme--colored.Toastify__toast--error {
	background: #f16063 !important;
}

.firebaseui-card-content,
.firebaseui-card-footer {
	padding: 0px !important;
}

.firebaseui-idp-button,
.firebaseui-tenant-button {
	/* background-color: #ff626e !important;
	color: white !important; */
	background-color: #ffffff !important;
	box-shadow: 0px 0px 1px 0px #0c1a4b1a !important;
	border: 1px solid #f2f2f2 !important;
	border-radius: 30px !important;
	min-height: 48px !important;
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
	max-width: unset !important;
}
